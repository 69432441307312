import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-axiom@1.2.0_@types+node@20.12.12_next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_i4qchfmyr5w7wjxobrulb2jgkq/node_modules/next-axiom/dist/webVitals/components.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.44.0_react-dom@18._hgdm5hppu6wtqk662p4tjyliqe/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogPageview","PHProvider"] */ "/app/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Fonts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ThemeSetter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/core/ui/Toast/toaster.tsx");
